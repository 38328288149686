import React from "react";
import './Product.css';

function Product({ icon: Icon, title, text }) {
  return (
    <div className="product-card flex items-center">
        <div className="icon-container mr-4 p-3">
          <Icon style={{ width: '75px' }}/>
        </div>

        <div>
          <h3 className="text-lg product-title">{title}</h3>
          <p className="text-sm product-text">{text}</p>
        </div>
      </div>
  );
}

export default Product;


    // <>
/*{ <Row className="product-card">
      <Col xs={12} lg={2} className="icon-col">
        <Icon />
      </Col>
      <Col xs={12} lg={10} className="text-col">
          <h3 className="text-lg product-title">{title}</h3>
      </Col>
      <Col xs={12} lg={10}>
      <p className="text-sm product-text">{text}</p>

      </Col>
    </Row>
    <div className="product-card" style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '10px' }}>
        <Icon style={{width: '60px'}} />
      </div>
      <div className="content-container" style={{ flex: 1 }}>
        <h2 className="text-lg product-title">{title}</h2>
      </div>
      <div>
      <p>{text}</p>

        </div>
    </div> }*/
   /* { </> }*/
